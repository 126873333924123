import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { Form } from "react-bootstrap";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import InfoCircleTooltip from "../../components/custom-widgets/info-circle-tooltip";
import Icon from "../../components/custom-widgets/icon";
import PersonalizationCards from "../../components/card/personalization-cards";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import HeroChevron from "../../components/hero/hero-chevron";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const Login = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "pb-login-page-hero",
    ...getHeroImgVariables(pageData),
    altText: "A couple with a contractor looking at their home remodeling plans.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Bills? Tuition? 1% HELOC Discount!"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Use your equity for whatever life brings."
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            text: "Learn More",
            url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit",
            class: "btn-white"
          }
        }
      ]
    }
  };
  const seoData = {
    title: "Personal Banking Login - Checking and Savings",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Personal Banking Login - Checking and Savings"
      },
      {
        name: "description",
        content:
          "Login to your personal bank account with WaFd Bank. Access your checking and savings accounts here with WaFd Bank (formally known as Washington Federal)."
      },
      {
        property: "og:title",
        content: "Personal Banking Login - Checking and Savings"
      },
      {
        property: "og:description",
        content:
          "Login to your personal bank account with WaFd Bank. Access your checking and savings accounts here with WaFd Bank (formally known as Washington Federal)."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/login"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/homepage/og-heloc-102324.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Personal Account Sign In"
    }
  ];
  // set the default dropdown to Online Banking
  useEffect(() => {
    setSelectedValue(values[0]);
  }, []);

  const [selectedValue, setSelectedValue] = useState("");
  const handleSelect = (e) => {
    setSelectedValue(values.find((n) => n.name === e));
  };
  const values = [
    {
      name: "Online Banking",
      id: "online-banking-option",
      buttonId: "online-banking-sign-in-button",
      href: "https://online.wafdbank.com/index.html"
    },
    {
      name: "Credit Card",
      id: "credit-card-option",
      buttonId: "credit-card-sign-in-button",
      href: "https://www.ezcardinfo.com/?WAFD=/",
      enrollLink: "https://www.ezcardinfo.com/?WAFD=/",
      forgotUsernameLink: "https://www.ezcardinfo.com/login/enrollment-card/true/false",
      forgotPasswordLink: "https://www.ezcardinfo.com/login/login-password"
    }
  ];

  const RightArrow = () => {
    return <Icon name="arrow-right" lib="far" class="ml-1" />;
  };

  const personalizationCardData = {
    defaultCardsArray: [1]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <SEO {...seoData} />
      <section className="container section-padding-adjusted">
        <h1>Sign in to your Personal Banking account</h1>
        <div className="row justify-content-between">
          <div className="col-md-6 col-xl-8">
            <div className="row">
              <div className="col-lg-10 col-xl-6">
                <Form.Group id="personal-banking-login-dropdrown" className="dropdown">
                  <Form.Label>
                    Select your account{" "}
                    <InfoCircleTooltip
                      id={"select-account-tooltip"}
                      trigger={["hover", "focus"]}
                      content={
                        "Sign in for personal accounts like checking, savings, personal credit card, or personal accounts being used for small business."
                      }
                      icon={{
                        title: "",
                        class: "text-gray-60",
                        name: "info-circle",
                        lib: "far"
                      }}
                    />
                  </Form.Label>
                  <Form.Control
                    id="select-account-type"
                    className="border-radius-6 select-input-text custom-select-chevron"
                    as="select"
                    defaultValue={""}
                    value={selectedValue.name}
                    onChange={(e) => handleSelect(e.target.value)}
                  >
                    {values.map((value, index) => (
                      <option key={index} value={value.name} id={value.id} className="dropdown-content">
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <a
                  className={`btn btn-block mb-3 ${!selectedValue.name ? "btn-disabled" : "btn-access-green"}`}
                  href={selectedValue?.href}
                  id={selectedValue?.buttonId}
                >
                  Sign In
                </a>
                <div className="mb-3">
                  First time user?{" "}
                  <a
                    id="online-banking-enroll-link"
                    href={selectedValue?.enrollLink || "https://online.wafdbank.com/link/register?application=OLB"}
                  >
                    Enroll Now
                  </a>
                </div>
                <div className="mb-3">
                  Forgot{" "}
                  <a
                    id="online-banking-forgot-username"
                    className="text-underline"
                    href={
                      selectedValue?.forgotUsernameLink ||
                      "https://online.wafdbank.com/link/forgotusername?application=olb"
                    }
                  >
                    Username
                  </a>{" "}
                  or{" "}
                  <a
                    id="online-banking-forgot-password"
                    className="text-underline"
                    href={
                      selectedValue?.forgotPasswordLink ||
                      "https://online.wafdbank.com/link/forgotpassword?application=olb"
                    }
                  >
                    Password?
                  </a>
                </div>
                <h4 className="font-weight-bold">Learn More</h4>
                <p>
                  <Link id="personal-banking-page-link" className="text-decoration-none" to="/personal-banking">
                    WaFd Personal account options
                    <RightArrow />
                  </Link>
                </p>
                <p>
                  <Link
                    id="credit-cards-page-link"
                    className="text-decoration-none"
                    to="/personal-banking/credit-cards"
                  >
                    WaFd Credit Cards
                    <RightArrow />
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <PersonalizationCards {...personalizationCardData} />
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Login;
